.items-title {
  margin: 2rem 0;

  h4 {
    font-size: 1.6rem;
  }

  p {
    font-size: 0.875rem;
  }
}

.item-card {
  .item-label {
    color: theme('colors.gray-six');
    font-weight: 700;
    font-size: 0.875rem;
  }

  .item-name {
    font-size: 1.2rem;
    font-weight: 600;
    color: black;
  }

  .item-price {
    margin: 0.625rem 0 0 0;
    font-size: 1.2rem;
    font-weight: 600;
    color: black;
  }

  .item-desc {
    margin: 1rem 0;
    font-size: 0.875rem;
  }
}

.more-bundle-details {
  p {
    margin-bottom: 1.6rem;
  }
}