@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --toastify-color-success: #19a756;
}

@layer base {
    body,
    html {
        font-family: 'montserrat', 'Montserrat', system-ui, sans-serif !important;
    }

    .absolute-center {
        @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
    }

    .floating-label input:focus-within:not([type='checkbox']) ~ label,
    .floating-label select:focus-within:not([type='checkbox']) ~ label,
    .floating-label input:not(:placeholder-shown):not([type='checkbox']):not(select) ~ label,
    .floating-label select:not(:placeholder-shown):not([type='checkbox']):not(select) ~ label {
        @apply -translate-y-6 scale-75 transform;
    }

    .floating-label input:not(:placeholder-shown):not([type='checkbox']):not(select) ~ label,
    .floating-label select:not(:placeholder-shown):not([type='checkbox']):not(select) ~ label {
        @apply font-bold text-white;
    }

    .floating-label input:focus-within:not([type='checkbox']) ~ label,
    .floating-label select:focus-within:not([type='checkbox']) ~ label {
        @apply opacity-100;
    }

    .floating-label.option-selected label {
        @apply -translate-y-6 scale-75 transform font-bold;
    }
}

@layer components {
    .font-heading {
        @apply uppercase;
    }

    .card {
        @apply rounded-lg bg-white p-6 text-black shadow-xl;
    }

    .card-dark {
        @apply rounded-lg bg-purple/20 p-6 shadow-xl;
    }

    .btn {
        @apply inline-block border border-transparent py-4 px-6 text-center font-heading font-bold leading-none tracking-widest ring-1 ring-inset ring-transparent transition duration-200 ease-out-quad disabled:pointer-events-none disabled:opacity-50 lg:py-6 lg:px-8 lg:text-lg lg:leading-none;
    }

    .btn-small {
        @apply btn !py-1 !px-2 lg:!py-2 lg:!px-4 lg:!text-base;
    }

    .btn-primary {
        @apply btn bg-gem-green text-white hover:bg-green-600 active:ring-gem-green disabled:bg-gem-green;
        border-radius: 1px;
    }

    .btn-primary-small {
        @apply btn-primary btn-small;
    }

    .btn-secondary {
        @apply btn bg-salmon hover:bg-blush active:ring-salmon disabled:bg-salmon;
    }

    .btn-secondary-small {
        @apply btn-secondary btn-small;
    }

    .btn-shell {
        @apply btn bg-gray-darker/0 ring-gray-darker/60 hover:bg-gray-darker/10 active:ring-gray-darker;
    }

    .btn-shell.active {
        @apply bg-gray-darker/10 ring-gray-darker;
    }

    .btn-shell-small {
        @apply btn-shell btn-small;
    }

    .main-nav-link {
        @apply relative block font-heading text-3xl font-normal tracking-widest transition duration-500 hover:translate-x-2 hover:opacity-100;
    }

    .main-nav-link-disabled {
        @apply relative block font-heading text-3xl font-normal tracking-widest opacity-20 transition duration-500;
    }

    .nav-link-active .diamond {
        @apply translate-x-0 opacity-100;
    }

    .layout-outlet-root {
        @apply px-5 lg:px-[60px] mb-4;
    }

    .swiper-react-active-thumb {
        @apply border-2 border-gem-green;
    }

    h1.font-heading,
    h2.font-heading,
    h3.font-heading,
    h4.font-heading,
    h5.font-heading {
        @apply font-bold;
    }

    .note-content {
        @apply space-y-2;
    }

    .note-content ul {
        @apply list-disc pl-4;
    }

    .note-content ol {
        @apply list-decimal pl-4;
    }

    .article-content {
        @apply space-y-4;
    }

    .article-content h1,
    .article-content h2,
    .article-content h3,
    .article-content h4,
    .article-content h5 {
        @apply font-heading font-bold text-gray-darker;
    }

    .article-content h2 {
        font-size: 2.441rem;
    }

    .article-content h3 {
        font-size: 1.953rem;
    }

    .article-content h4 {
        font-size: 1.563rem;
    }

    .article-content h5 {
        font-size: 1.25rem;
    }

    .article-content h6 + h4 {
        margin-top: 0 !important;
    }

    .article-content .w-richtext-figure-type-video {
        @apply relative overflow-hidden bg-gray-six;
    }

    .article-content .w-richtext-figure-type-video iframe {
        @apply absolute-center h-full w-full;
    }

    .outline-text {
        -webkit-text-stroke: 2px currentColor;
        -webkit-text-fill-color: transparent;
    }

    .link {
        @apply border-b-2 border-b-green-400 font-bold;
    }

    #rswp-card-button {
        @apply btn-primary rounded-none;
    }

    .rating-btn {
        @apply bg-red/30 hover:bg-red;
    }

    .rating-btn:nth-of-type(7) {
        @apply bg-yellow/30 hover:bg-yellow;
    }

    .rating-btn:nth-of-type(8) {
        @apply bg-yellow/30 hover:bg-yellow;
    }

    .rating-btn:nth-of-type(9) {
        @apply bg-gem-green/30 hover:bg-gem-green;
    }

    .rating-btn:nth-of-type(10) {
        @apply bg-gem-green/30 hover:bg-gem-green;
    }

    .rating-btn.active {
        @apply bg-red;
    }

    .rating-btn.active:nth-of-type(7),
    .rating-btn.active:nth-of-type(8) {
        @apply bg-yellow;
    }

    .rating-btn.active:nth-of-type(9),
    .rating-btn.active:nth-of-type(10) {
        @apply bg-gem-green;
    }

    .modal-content a {
        @apply link;
    }

    a[href^='mailto'],
    a[href^='tel'] {
        @apply link;
    }

    .responsive-video {
        position: relative;
        overflow: hidden;
    }

    .responsive-video:before {
        content: 'Loading video...';
        color: #fff;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        z-index: 1;
    }

    .responsive-video:after {
        content: '';
        display: block;
        padding-bottom: 56.25%;
    }

    .responsive-video > object,
    .responsive-video > video,
    .responsive-video > iframe,
    .responsive-video > embed,
    .responsive-video > img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }

    .terms-content-wrapper h1,
    .terms-content-wrapper h2,
    .terms-content-wrapper h3,
    .terms-content-wrapper h4,
    .terms-content-wrapper h5 {
        @apply font-bold text-gem-green;
        margin-bottom: 1rem;
    }

    .terms-content-wrapper p {
        margin-bottom: 1rem;
    }

    .admin-view.is-windows select {
        @apply !bg-white !p-2 !text-black;
    }
}

@layer utilities {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='radio']:checked + svg,
    input[type='checkbox']:checked + svg {
        opacity: 1 !important;
    }
}
