.react-datepicker {
    width: 100%;
    background-color: transparent;
    min-height: 250px;
    border-radius: 0;
    background-color: #ffffff;
    border: none;
    margin: 0;
}

.react-datepicker__month-container {
    width: 100%;
    float: none;
    margin-bottom: 2rem;
}
.react-datepicker__month-container .react-datepicker__month {
    margin: 0;
}
.react-datepicker__navigation {
    height: 20px;
    line-height: unset;
    width: unset;
    margin-inline: calc(50% - 125px);
}

.react-datepicker__navigation-icon--previous,
.react-datepicker__navigation-icon--next {
    opacity: 0;
}

.react-datepicker__navigation--previous::before,
.react-datepicker__navigation--next::before {
    content: '';
    display: inline-block;
    color: rgb(0, 0, 0);
    width: 8px;
    height: 8px;
    border-style: solid;
    border-width: 2px 2px 0 0;
    position: relative;
}
.react-datepicker__navigation--previous::before {
    transform: rotate(-135deg);
    left: 2px;
}
.react-datepicker__navigation--next::before {
    transform: rotate(45deg);
    left: -2px;
}
.react-datepicker__month {
    width: 100%;
}
.react-datepicker__header {
    background-color: inherit;
    border: 0px;
    padding: 0;
}
.react-datepicker__current-month {
    color: rgb(81, 81, 81);
    font-size: 14px;
    font-weight: 900;
    line-height: 21px;
    text-align: center;
    width: 200px;
    margin: auto;
}
.react-datepicker__day-name {
    color: rgb(208, 208, 208);
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    margin-right: 10px;
}
.react-datepicker__day-names {
    margin-top: 22px;
    margin-bottom: 13px;
    align-items: center;
    justify-content: space-evenly;
}
.react-datepicker__day {
    color: rgb(51, 51, 51);
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    padding-top: 1px;
    margin-right: 10px;
    margin-bottom: 6px;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.react-datepicker__day:hover {
    border-radius: 50%;
}
.react-datepicker__day--today {
    color: rgb(74, 144, 226);
    font-weight: 900;
}
.react-datepicker__day--highlighted {
    background-color: rgba(0, 170, 99, 0.1);
}
.react-datepicker__day--highlighted:hover {
    background-color: rgba(0, 170, 99, 0.7);
    color: #fff;
}
.react-datepicker__day--disabled {
    color: #dadada;
    background-color: inherit;
}
.react-datepicker__day--selected {
    background-color: #00aa63;
    color: #fff;
}
.react-datepicker__day--outside-month {
    visibility: hidden;
}

.react-datepicker__day--keyboard-selected {
    background-color: lightblue;
    font-weight: 900;
}

@media (max-width: theme('screens.lg')) {
    .react-datepicker__day {
        width: 1.7rem;
        height: 1.7rem;
    }
}

@media (min-width: theme('screens.lg')) {
    .react-datepicker__day-name {
        width: 38px;
    }

    .react-datepicker__navigation--previous::before {
        left: 2px;
    }
    .react-datepicker__navigation--next::before {
        left: -2px;
    }
}
