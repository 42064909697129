.gem-text-answer-list {
    li {
        display: flex;
        justify-content: flex-start;
        padding: 1rem;
        align-items: top;
        transition: opacity 0.2s linear;
        cursor: pointer;

        &.selected-choice {
            .choice-checkbox {
                background-color: theme('colors.gem-green');
            }

            .checkmark {
                display: block;
            }
        }

        &.disabled-choice {
            opacity: 0.5;
            cursor: default;
        }
    }

    .checkmark {
        display: none;
    }

    .choice-checkbox {
        display: block;
        position: relative;
        width: 20px;
        height: 20px;
        border: theme('colors.gem-green') solid 4px;
        margin-right: 1rem;
        margin-top: 0.2rem;
    }

    .choice-label {
        display: block;
        font-weight: 600;
        flex: 1;
    }
}
