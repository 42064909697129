.UnauthenticatedLayout>.container {
  padding-top: 5rem;
}

.sign-in-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  justify-content: center;
  background: theme('colors.purple-super-dark');
  height: 4rem;
  display: flex;
  align-items: center;
  z-index: 20;

  .btn-sign-in {
    margin-left: 0.25rem;
  }
}

@media screen and (max-width: theme('screens.md')) {
  .UnauthenticatedLayout>.container {
    padding-top: 5rem;
  }

  .sign-in-container {
    height: 4rem;
    width: 100%;
    justify-content: center;
    background: theme('colors.purple-super-dark');
    // .btn-sign-in {
    //   margin-left: 0;
    //   margin-top: 0.65rem;
    // }
  }
}