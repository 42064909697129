.care-team-member-image-frame {
  position: relative;
  width: 100%;
  padding-top: 100%;
  background: #ccc;
}

.care-team-member-image {
  position: absolute;
  top:0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  background-color: #ccc;
}

section.clinical-team {
  margin: 4rem 0;
}

header.clinical-team {
  margin-bottom: 3rem;
}

.clinical-team-list {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.clinical-team-list li {
  flex: 1;
  max-width: 165px;
}

@media screen and (max-width: 768px) {
  .clinical-team-list li {
    flex: auto;
    width: 100%;
    max-width: 100%;
  }
  
}